import React, { useState,useEffect,useCallback } from "react";
import etichetta from './svg.js';


const Step3 = props => {


  const [classeenergetica, setclasseenergetica] = useState([]);
  //
  // if (props.currentStep !== 3) {
  //   return null;
  // }

  const calcolaCaldaia = useCallback(async () => {
      const data = await fetch(`/api/tabellac/${props.state.fonteenerg}/${props.state.tipocaldaia}/${props.state.annoinstallazione}`);
      const items = await data.json();

      // TABELLA C
      props.setValue({name:'tabellac',value:true})

      const pn=props.state.pn??24
      // props.setValue({name:'pn',value:pn})

      const n100 = props.state.n100 ?? (items[0].c1 + (items[0].c2 * Math.log10(pn)))
      const n30 =  props.state.n30 ?? (items[0].c3 + (items[0].c4 * Math.log10(pn)))
      const elmax= props.state.elmax ?? ((items[0].c7Pn + (items[0].c8Pn * Math.pow(pn , items[0].nPn )))/1000)
      const elmin= props.state.elmin ?? ((items[0].c7P1 + (items[0].c8P1 * Math.pow(pn , items[0].nP1 )))/1000)
      const psb= props.state.psb ?? ((items[0].c7SB + (items[0].c8SB * Math.pow(pn , items[0].nSB )))/1000)
      const pstby= props.state.pstby ?? ((items[0].c5 * Math.pow(pn , items[0].c6 )) / 1000)
      const pign= props.state.pign ?? (0.150)


      // props.setValue({name:'n100',value:n100})
      // props.setValue({name:'n30',value:n30})
      // props.setValue({name:'elmax',value:elmax})
      // props.setValue({name:'elmin',value:elmin})
      // props.setValue({name:'psb',value:psb})
      // props.setValue({name:'pstby',value:pstby})
      // props.setValue({name:'pign',value:pign})

      // TABELLA E

      let cage=0;
      const eta=(new Date().getFullYear() - props.state.annoinstallazione)

      if(props.state.manutenzione==='true'){
        switch(true) {
          case (eta<10):
            cage=1
            break;
          case (eta<15):
            cage=0.98
            break;
          case (eta<20):
            cage=0.95
            break;
          case (eta<25):
            cage=0.90
            break;
          case (eta<30):
            cage=0.88
            break;
          default:
            cage=0.87
            break;
          }
      }else{
        switch(true) {
          case (eta<10):
            cage=0.86
            break;
          case (eta<15):
            cage=0.80
            break;
          case (eta<20):
            cage=0.74
            break;
          case (eta<25):
            cage=0.69
            break;
          case (eta<30):
            cage=0.64
            break;
          default:
            cage=0.59
            break;
          }
      }

      // TABELLA F
      const f1 = 3
      const f2 = (2.5 * ((0.15 * elmax) + (0.85 * elmin) + (1.3 * psb)) * 100) / ((0.15 * pn) + (0.85 * 0.30 * pn))
      const f3 = (0.5 * pstby * 100) / pn
      const f4 = (1.3 * pign * 100) / pn

      // TABELLA d
      let pci_pcs=0
      if(props.state.fonteenerg==='Gas'){
        pci_pcs=0.9
      }
      if(props.state.fonteenerg==='Gasolio'){
        pci_pcs=0.94
      }
      if(props.state.fonteenerg==='Biomassa (pellet)'){
        pci_pcs=0.93
      }
      if(props.state.fonteenerg==='Biomassa (cippato)'){
        pci_pcs=0.93
      }


      const ns = (((0.85 * n30 * pci_pcs) + (0.15 * n100 * pci_pcs)) * cage) - (f1 + f2 + f3 + f4) - 3
      let classeenergeticaT=''
      let etichetta=''
      switch(true) {
        case (ns<36):
          classeenergeticaT='-'
          etichetta='-'
          break;
        case (ns<75):
          classeenergeticaT='D'
          etichetta='D'
          break;
        case (ns<82):
          classeenergeticaT='C'
          etichetta='C'
          break;
        case (ns<90):
          classeenergeticaT='B'
          etichetta='B'
          break;
        case (ns<98):
          classeenergeticaT='A'
          etichetta='A'
          break;
        case (ns<125):
          classeenergeticaT='A<sup>+</sup>'
          etichetta='AA'
          break;
        case (ns<150):
          classeenergeticaT='A<sup>++</sup>'
          etichetta='AAA'
          break;
        default:
          classeenergeticaT='A<sup>+++</sup>'
          etichetta='AAAA'
          break;
        }

        setclasseenergetica({...classeenergetica,caldaia:classeenergeticaT})

        props.setValue({name:'etichettaclasseenergeticacaldaia',value:etichetta})
        props.setValue({name:'efficienzacaldaia',value:Math.round(ns)})
        props.setValue({name:'classeenergeticacaldaia',value:classeenergeticaT})


        // const classeEnergetica=
        return {classeenergeticacaldaia:classeenergeticaT,efficienzacaldaia:Math.round(ns)}

  }, [classeenergetica, props]);


  const calcolaScaldaacqua = useCallback(async () => {



      const data = await fetch(`/api/tabellam/${props.state.fonteenerg}/Standard/${props.state.annoinstallazione}`);
      const items = await data.json();

      // TABELLA C
      props.setValue({name:'tabellam',value:true})

      let profilocarico=''
      if(props.state.numerofruitori){
        const numerofruitori=props.state.numerofruitori
        if(props.state.utente==="true" && props.state.fonteenerg==="Elettrico"  && props.state.tiposcaldaacqua==="istantaneo"){
          profilocarico='S'

        }else{

          switch(true) {
            case (numerofruitori===0):
              profilocarico='S'
              break;
            case (numerofruitori<3):
              profilocarico='M'
              break;
            case (numerofruitori<6):
              profilocarico='L'
              break;
            case (numerofruitori<9):
              profilocarico='XL'
              break;
            default:
              profilocarico='XXL'
              break;
            }
          }

      }else{
        profilocarico=props.state.profilocarico
      }


      let volumeaccumulo=0

      const data1 = await fetch(`/api/tabellai/${props.state.fonteenerg}/${props.state.tiposcaldaacqua}/${profilocarico}`);
      const items1 = await data1.json();
      // if(props.state.pn && ){
      //   const pn=props.state.pn
      // }else{
      const pn=props.state.pn??items1[0].valore
      // }
      // if(props.state.volumeaccumulo){
      //   const volumeaccumulo=props.state.volumeaccumulo
      // }else{
      if(props.state.fonteenerg==="Elettrico" ||  props.state.tiposcaldaacqua!=="istantaneo"){
        volumeaccumulo=props.state.volumeaccumulo??items1[1].valore
      }
      // }



      // props.setValue({name:'pn',value:pn})
      // props.setValue({name:'volumeaccumulo',value:volumeaccumulo})
      let n100 =0
      let elmax =0
      let psb =0
      // const n100 = props.state.n100 ?? (items[0].c1 + (items[0].c2 * Math.log10(pn)))
      // const elmax= props.state.elmax ?? ((items[0].c7Pn + (items[0].c8Pn * Math.pow(pn , items[0].nPn )))/1000)
      // const psb= props.state.psb ?? ((items[0].c7SB + (items[0].c8SB * Math.pow(pn , items[0].nSB )))/1000)
      if(props.state.fonteenerg!=='Elettrico'){

         n100 = (items[0].c1 + (items[0].c2 * Math.log10(pn)))
         elmax= ((items[0].c7Pn + (items[0].c8Pn * Math.pow(pn , items[0].nPn )))/1000)
         psb= ((items[0].c7SB + (items[0].c8SB * Math.pow(pn , items[0].nSB )))/1000)
      }

      // props.setValue({name:'n100',value:n100})
      // props.setValue({name:'elmax',value:elmax})
      // props.setValue({name:'psb',value:psb})

      let qref=0

      switch(profilocarico) {
        case 'S':
          qref=2.1
          break;
        case 'M':
          qref=5.845
          break;
        case 'L':
          qref=11.655
          break;
        case 'XL':
          qref=19.07
          break;
        case 'XXL':
          qref=24.53
          break;
        default :
          qref=24.53
          break;


        }
        const scaldaacquaelettrico=props.state.scaldaacquaelettrico ??"Verticale"

        const camb=16
        const cset=55
        const t=24
        const fsto_dis_ls=3
        const d1=scaldaacquaelettrico==="Verticale"?0.1474:0.939
        const d2=scaldaacquaelettrico==="Verticale"?0.0719:0.0104
        const d3=scaldaacquaelettrico==="Verticale"?0.6667:1
        const d4=45
        const d5=24
        const h=volumeaccumulo===0?0:(1000*(d1+(d2*Math.pow(volumeaccumulo , d3 )))/(d4*d5))

        const qls=fsto_dis_ls*h*(cset-camb)*t/1000

      // TABELLA E

      let cage=0;
      const eta=(new Date().getFullYear() - props.state.annoinstallazione)

      const data2 = await fetch(`/api/tabellao/${props.state.fonteenerg}/${eta}`);
      const items2 = await data2.json();


      if(props.state.manutenzione==='true'){
        cage=items2[0].manutsi
      }else{
        cage=items2[0].manutno
      }

      let qel=0
      let qfuel=0

      // TABELLA d
      let pci_pcs=0
      if(props.state.fonteenerg==='Gas'){
         pci_pcs=0.9
      }
      if(props.state.fonteenerg==='Gasolio'){
         pci_pcs=0.94
      }
      if(props.state.fonteenerg==='Biomassa (pellet)'){
         pci_pcs=0.93
      }
      if(props.state.fonteenerg==='Biomassa (cippato)'){
         pci_pcs=0.93
      }

      let qcorr=0


      if(props.state.fonteenerg==='Elettrico'){
        qel=qref+qls
        qcorr = (-0.23) * 2.5 * (qel - qref)

      }else{
        qel=(elmax*2)+(psb*22)
        qfuel=100*(qref+qls)/(n100*pci_pcs)

        qcorr = (-0.23) * (qfuel - qref)
      }

      if(profilocarico==='XXL'){
        qcorr=0
      }

      const cc=2.5
      const nWH=((qref*cage)/((qfuel+(cc*qel))+qcorr))*100

      const data3 = await fetch(`/api/tabellaq/${nWH}/${profilocarico}`);
      const items3 = await data3.json();
      props.setValue({name:'novalue',value:false})
      const classeenergeticaT='A'
      if(!items3[0]){
        props.setValue({name:'novalue',value:true})
      } else{
      const classeenergeticaT=items3[0].Classe
      props.setValue({name:'efficienzascaldaacqua',value:Math.round(nWH)})
      props.setValue({name:'classeenergeticascaldaacqua',value:classeenergeticaT})
      props.setValue({name:'etichettaclasseenergeticascaldaacqua',value:items3[0].etichetta})
      props.setValue({name:'qvalue',value:items3[0][((props.state.utente==="true" && props.state.fonteenerg==="Elettrico"  && props.state.tiposcaldaacqua==="istantaneo")?'S': profilocarico)]})

      setclasseenergetica({...classeenergetica,scaldaacqua:classeenergeticaT})
      }
      // const classeEnergetica=
      return {classeenergeticascaldaacqua:classeenergeticaT,efficienzascaldaacqua:Math.round(nWH)}

    }, [props, classeenergetica]);

    const salvadati = useCallback((d) => {
      const newData={...props.state,...d}

       console.log(newData)

       localStorage.setItem("valoriutente", JSON.stringify(newData));
  //    localStorage.setItem("valoriutente", JSON.stringify({"utente":props.state.utente,sistema:props.state.sistema,"regione":props.state.regione,"fonteenerg":props.state.fonteenerg,"tipocaldaia":props.state.tipocaldaia,"annoinstallazione":props.state.annoinstallazione,"manutenzione":props.state.manutenzione,"comune":props.state.comune,"tiposcaldaacqua":props.state.tiposcaldaacqua,"numerofruitori":props.state.numerofruitori}));

      fetch('/api/storedata', {
              method: 'POST',
              // We convert the React state to JSON and send it as the POST body
              body: JSON.stringify(newData),
              headers: {
                  "Content-Type": "application/json"
              }
            }).then(function(response) {
              return;
            });


    }, [props.state]);



  useEffect( () => {
    if (props.currentStep === 3 && props.state.sistema==="1" && !props.state.tabellam) {
      calcolaScaldaacqua().then((newData)=>salvadati(newData));

    }

    if (props.currentStep === 3 && props.state.sistema==="2" && !props.state.tabellac) {
      calcolaCaldaia().then((newData)=>salvadati(newData));
    }

    if (props.currentStep === 3 && props.state.sistema==="3" && (!props.state.tabellam || !props.state.tabellac)) {
      Promise.all([calcolaScaldaacqua(),calcolaCaldaia()]).then((v)=>salvadati({...v[0],...v[1]}));
    }
  }, [props, calcolaCaldaia, calcolaScaldaacqua, salvadati]);


  return (
    <div>
    {
      (props.state.sistema==="2" || props.state.sistema==="3")&&
            <div className="max-w-lg bg-white p-4 md:p-12 container mx-auto">
              <div id="existing_water_heating_system_calculated_label" >
                <div className="text-xl pb-12">

                   {(props.state.efficienzacaldaia < 36)&&
                    <p>
                    L'efficienza energetica stimata è inferiore al 36%
                    </p>
                    }
                    {
                    (props.state.efficienzacaldaia >= 36)&&
                    <p>
                    L'Efficienza energetica stimata: <span id="water_heating_system_efficiency_placeholder"><span dangerouslySetInnerHTML={{__html: `${props.state.efficienzacaldaia}`}} /></span>%
                    </p>
                    }

                  <p>Classe energetica stimata: <span id="water_heating_label_class_placeholder"><span dangerouslySetInnerHTML={{__html: `<strong>${props.state.classeenergeticacaldaia}</strong>`}} /></span></p>
                </div>
                {
                  (props.state.efficienzacaldaia >= 36)&&
                  <div className="pb-12 text-center">
                  <img src={etichetta[`etichetta_C_${props.state.etichettaclasseenergeticacaldaia}`]} alt="Etichetta" className="w-50 m-auto" />
                  </div>
                }
              </div>
            </div>
          }
          {
      (props.state.sistema==="1" || props.state.sistema==="3")&&
           <div className="max-w-lg bg-white p-4 md:p-12 container mx-auto">
             <div id="existing_water_heating_system_calculated_label" >
                <div className="text-xl pb-12">

                {/*consol.log({props.state.qvalue});*/}
                   {(props.state.efficienzascaldaacqua < props.state.qvalue || props.state.novalue)&&
                    <p>
                    Non è possibile stimare la classe energetica del generatore. Si prega di verificare i dati inseriti
                    </p>
                    }
                    {
                    (props.state.efficienzascaldaacqua >= props.state.qvalue)&&
                    <div>
                    <p>
                      Efficienza energetica stimata: <span id="water_heating_system_efficiency_placeholder">{props.state.efficienzascaldaacqua}</span>%
                    </p>
                    <p>Classe energetica stimata: <span id="water_heating_label_class_placeholder"><span dangerouslySetInnerHTML={{__html: `<strong>${props.state.classeenergeticascaldaacqua}</strong>`}} /></span></p>
                    </div>
                    }
                
                  
                </div>
                {(props.state.efficienzascaldaacqua >= props.state.qvalue)&&
               <div className="pb-12 text-center">
               <img src={etichetta[`etichetta_S_${props.state.etichettaclasseenergeticascaldaacqua}`]} alt="Etichetta" className="w-50 m-auto" />
               </div>
               }
             </div>
           </div>
         }
      </div>
    )
};
export default Step3;
