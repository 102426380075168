import {React, useState} from 'react';
import {FormGroup, Input, Label, Popover, PopoverBody, FormFeedback } from 'reactstrap';

const SubstepA = props => {
      const [popoverOpen, setPopoverOpen] = useState(false);
     // const [selectdManutenzione2, setSelectedUManutenzione2] = useState(props.state.manutenzione);

      const changeManutenzione2 = event => {
        //    setSelectedUser(event.target.value);
            props.handleChange(event)
          }
      const changeFontes = event => {
        //  setSelectedFontes(event.target.value)
          props.handleChange(event)
      }
      const changeTipos = event => {
        // setSelectedTipo(event.target.value)
          props.handleChange(event)
      }
      const changeScaldaEl = event => {
          // setSelectedTipo(event.target.value)
          props.handleChange(event)
      }
      const changeAnnoS =(event,onBlur=false) => {
        //   setSelectedAnno(event.target.value)
        let { value, min, max } = event.target;
        if(onBlur){
          value = Math.max(Number(min), Math.min(Number(max), Number(value)));
        }
        event.target.value= value;

        props.handleChange(event)
      }
      const changeNumerofruitori = event => {
        //   setSelectedAnno(event.target.value)
        let { value, min, max } = event.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        event.target.value= value;
        props.handleChange(event)
      }
      const changeProfilocarico = event => {
        //   setSelectedAnno(event.target.value)
        props.handleChange(event)
      }
      const changepn = event => {
        //   setSelectedAnno(event.target.value)
        props.handleChange(event)
      }
      const changeVolumeaccumulo = event => {
        //   setSelectedAnno(event.target.value)
        props.handleChange(event)
      }
      const changen100 = event => {
        //   setSelectedAnno(event.target.value)
        props.handleChange(event)
      }
      const changeelmax = event => {
        //   setSelectedAnno(event.target.value)
        props.handleChange(event)
      }
      const changepsb = event => {
        //   setSelectedAnno(event.target.value)
        props.handleChange(event)
      }
    return (
    <>
     <div className="max-w-lg bg-white p-4 md:p-12 container mx-auto">
          <p className="block text-gray-700 text-2xl font-bold mb-2">{(props.state.sistema==="1")?'' : 'Dati sul servizio di acqua calda sanitaria'}</p>
            <FormGroup className={(props.state.sistema==='1')?'':'d-none'}>
              <Label for="fonteenerg" className="label-std">* Fonte energetica:</Label>
              <Input
                type={"select"}
                name="fonteenerg"
                id="fonteenerg"
                value={props.state.fonteenerg??0}
                className="select-std"
                onChange={changeFontes}
                invalid={ !props.state.validate.fonteenerg }

                required
              >
                <option value="0" hidden>Scegli un’opzione</option>
                <option value={"Gas"}>gas</option>
                <option value={"Gasolio"}>gasolio</option>
                <option value={"Biomassa (pellet)"}>biomassa (pellet)</option>
                <option value={"Biomassa (cippato)"}>biomassa (cippato)</option>
                <option value={"Elettrico"}>elettricità</option>
              </Input>
              <FormFeedback>
                Campo obbligatorio.
              </FormFeedback>

            </FormGroup>
            <FormGroup>
              <Label for="tiposcaldaacqua" className="label-std">* Tipo di scaldaacqua:</Label>
              <Input
                type={"select"}
                name="tiposcaldaacqua"
                id="tiposcaldaacqua"
                value={props.state.tiposcaldaacqua??0}
                className="select-std"
                onChange={changeTipos}
                invalid={ !props.state.validate.tiposcaldaacqua }

                required
              >
                <option value="0" hidden>Scegli un’opzione</option>
                <option value={"con accumulo"}>con accumulo</option>
                <option value={"istantaneo"}>istantaneo</option>
              </Input>
              <FormFeedback>
                Campo obbligatorio.
              </FormFeedback>

            </FormGroup>
            {((props.state.sistema==="1" && props.state.tiposcaldaacqua==="con accumulo")) && (
                <FormGroup>
                <Label for="scaldaacquaelettrico" className="label-std">Lo scaldaacqua è:</Label>
                <Input
                  type={"select"}
                  name="scaldaacquaelettrico"
                  id="scaldaacquaelettrico"
                  value={props.state.scaldaacquaelettrico??"Verticale"}
                  className="select-std"
                  onChange={changeScaldaEl}
                //  invalid={ !props.state.validate.scaldaacquaelettrico }

                //  value={props.state.scaldaacquaelettrico??''}
                  required
                >
                  <option value={"Verticale"}>Verticale</option>
                  <option value={"Orizzontale"}>Orizzontale</option>
                </Input>
                <FormFeedback>
                  Campo obbligatorio.
                </FormFeedback>

              </FormGroup>
              )
            }

            <FormGroup  className={(props.state.sistema==='1')?'':'d-none'}>

              <Label for="annoinstallazione" className="label-std">* Anno d'installazione </Label>

              <Input
                type="number"
                name="annoinstallazione"
                id="annoinstallazione"
                min="1978"
                max={new Date().getFullYear()}
                step="1"
                onChange={changeAnnoS}
                onBlur={(e)=>changeAnnoS(e,true)}
                value={props.state.annoinstallazione??''}
                invalid={ !props.state.validate.annoinstallazione }

                required
              />
              <small id="emailHelp" className="form-text text-muted">Scegliere 1978 anche se il generatore è stato installato prima di questa data</small>
              {props.state.annoinstallazione >="2016"  && (
                  <div className="mt-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">HARPa è applicabile a generatori sprovvisti di etichetta energetica</div>
              )}
              <FormFeedback>
                Campo obbligatorio.
              </FormFeedback>

            </FormGroup>
            <div className={props.state.utente==="false"?'d-none':''}>
            <span className="simple-left-container d-none">
              <img src="img/info.svg" alt="" className="js-tooltip" id="PopoverExample2"/>
                  <Popover
                      isOpen={popoverOpen}
                      placement="right"
                      target="PopoverExample2"
                      toggle={() => { setPopoverOpen(!popoverOpen) }}>
                        <PopoverBody>Si tratta del numero di persone che usano l’abitazione contemporaneamente.</PopoverBody>
                  </Popover>
            </span>
            <FormGroup>
                <Label for="numerofruitori" className="label-std">* Numero di persone che usano l’abitazione</Label>
                <Input
                  type="number"
                  name="numerofruitori"
                  min="1"
                  max="100"
                  step="1"
                  id="numerofruitori"
                  placeholder=""
                  value={props.state.numerofruitori??''}
                  onChange={changeNumerofruitori}
                  invalid={ !props.state.validate.numerofruitori }

                  required
                />
                <FormFeedback>
                  Campo obbligatorio.
                </FormFeedback>

            </FormGroup>
            </div>
            <div className={props.state.utente==="true"?'d-none':''}>

            <FormGroup>
                <Label for="profilocarico" className="label-std">* Profilo di carico</Label>
                <Input
                  type={"select"}
                  name="profilocarico"
                  id="profilocarico"
                  className="select-std"
                  value={props.state.profilocarico??0}
                  invalid={ !props.state.validate.profilocarico }

                //  value={props.state.profilocarico??''}
                  onChange={changeProfilocarico}
                  required
                  >
                  <option value="0" hidden>Scegli un’opzione</option>
                  {((props.state.fonteenerg==="Elettrico" && props.state.sistema==="1" && props.state.tiposcaldaacqua==="istantaneo")) ? (
                    <>
                     <option value={"S"}>S</option>
                     </>)
                  :
                    (<>
                    <option value={"S"}>S</option>

                      <option value={"M"}>M</option>
                      <option value={"L"}>L</option>
                      <option value={"XL"}>XL</option>
                      <option value={"XXL"}>XXL</option>
                    </>
                  )}

                </Input>
                <FormFeedback>
                  Campo obbligatorio.
                </FormFeedback>

            </FormGroup>
            </div>
            {(props.state.sistema==='1') && <FormGroup >
              <Label for="manutenzione2" className="label-std">* Il generatore è sottoposto a verifica periodica e regolare manutenzione da parte di un professionista abilitato? </Label>
              Negli ultimi cinque anni l’apparecchio è stato sottoposto a manutenzione da parte di un professionista abilitato?<br/><br/>
              <Input
                type="radio"
                name="manutenzione"
                id="manutenzione_si2"
                value="true"
                checked={props.state.manutenzione=== 'true'}
                onChange={changeManutenzione2}
                className="toggle-label-std"
              />
              <label htmlFor="manutenzione_si2" className="mr-5 ml-2">Si</label>
              <Input
                type="radio"
                name="manutenzione"
                id="manutenzione_no2"
                value="false"
                checked={props.state.manutenzione === 'false'}
                onChange={changeManutenzione2}
              />
              <label htmlFor="manutenzione_no2" className="mr-5 ml-2">No</label>
            </FormGroup>

          }
            <div className={props.state.utente==="true"?'d-none':'grid grid-cols-2 gap-2'}>

              <FormGroup className={(props.state.sistema==='1')?'':'d-none'}>
                <Label for="pn" className="label-std">P<sub>n</sub> (in kW)</Label>
                <Input
                  type="number"
                  name="pn"
                  id="pn"
                  className="w-75"
                  placeholder=""
                  value={props.state.pn??''}
                  onChange={changepn}
                  required
                />
                <FormFeedback>
                  Campo obbligatorio.
                </FormFeedback>

              </FormGroup>

              {((props.state.sistema==="1" && props.state.fonteenerg==="Elettrico") ||  props.state.tiposcaldaacqua!=="istantaneo") && (

              <FormGroup>
                <Label for="volumeaccumulo" className="label-std">Volume d’accumulo (in litri)</Label>
                <Input
                  type="number"
                  name="volumeaccumulo"
                  id="volumeaccumulo"
                  className="w-75"
                  placeholder=""
                  value={props.state.volumeaccumulo??''}
                  onChange={changeVolumeaccumulo}
                />
              </FormGroup>
            )}
            {(props.state.sistema==="1" && props.state.fonteenerg!=="Elettrico") && (
              <>

                <FormGroup className={(props.state.sistema==='1')?'':'d-none'}>
                  <Label for="n100" className="label-std">η<sub>100</sub> (da 0 a 100)</Label>
                  <Input
                    type="number"
                    name="n100"
                    id="n100"
                    className="w-75"
                    placeholder=""
                    value={props.state.n100??''}
                    onChange={changen100}
                  />
                </FormGroup>
                <FormGroup className={(props.state.sistema==='1')?'':'d-none'}>
                  <Label for="elmax" className="label-std">el<sub>max</sub> (in kW)</Label>
                  <Input
                    type="number"
                    name="elmax"
                    id="elmax"
                    className="w-75"
                    placeholder=""
                    value={props.state.elmax??''}
                    onChange={changeelmax}
                  />
                </FormGroup>
                <FormGroup className={(props.state.sistema==='1')?'':'d-none'}>
                  <Label for="psb" className="label-std">P<sub>SB</sub> (in kW)</Label>
                  <Input
                    type="number"
                    name="psb"
                    id="psb"
                    className="w-75"
                    placeholder=""
                    value={props.state.psb??''}
                    onChange={changepsb}
                  />
                </FormGroup>
              </>
            )}

          </div>
        </div>
    </>
  );
};

export default SubstepA;
