import etichetta_S_AAAA from '../svg/etichetta_S_AAAA.jpg';
import etichetta_S_AAA from '../svg/etichetta_S_AAA.jpg';
import etichetta_S_AA from '../svg/etichetta_S_AA.jpg';
import etichetta_S_A from '../svg/etichetta_S_A.jpg';
import etichetta_S_B from '../svg/etichetta_S_B.jpg';
import etichetta_S_C from '../svg/etichetta_S_C.jpg';
import etichetta_S_D from '../svg/etichetta_S_D.jpg';
import etichetta_S_E from '../svg/etichetta_S_E.jpg';
import etichetta_S_F from '../svg/etichetta_S_F.jpg';
//import etichetta_S_G from '../svg/etichetta_S_G.jpg';
import etichetta_C_AAAA from '../svg/etichetta_C_AAAA.jpg';
import etichetta_C_AAA from '../svg/etichetta_C_AAA.jpg';
import etichetta_C_AA from '../svg/etichetta_C_AA.jpg';
import etichetta_C_A from '../svg/etichetta_C_A.jpg';
import etichetta_C_B from '../svg/etichetta_C_B.jpg';
import etichetta_C_C from '../svg/etichetta_C_C.jpg';
import etichetta_C_D from '../svg/etichetta_C_D.jpg';
//import etichetta_C_E from '../svg/etichetta_C_E.jpg';
//import etichetta_C_F from '../svg/etichetta_C_F.jpg';
//import etichetta_C_G from '../svg/etichetta_C_G.jpg';



const etichetta = {
    etichetta_S_AAAA,
    etichetta_S_AAA,
    etichetta_S_AA,
    etichetta_S_A,
    etichetta_S_B,
    etichetta_S_C,
    etichetta_S_D,
    etichetta_S_E,
    etichetta_S_F,
    //etichetta_S_G,
    etichetta_C_AAAA,
    etichetta_C_AAA,
    etichetta_C_AA,
    etichetta_C_A,
    etichetta_C_B,
    etichetta_C_C,
    etichetta_C_D,
    //etichetta_C_E,
    //etichetta_C_F,
    //etichetta_C_G,
};

export default etichetta;