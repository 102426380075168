import React from "react";
import { Link } from "react-router-dom";


function FooterBottom() {
  return (
    <div>
        <div className="mx-0 mb-0 pt-2 bg-white text-center text-gray-700 text-sm">
          <p>
            Questo strumento, realizzato da ENEA DUEE, è l’adattamento italiano dell’applicazione HARPa, sviluppata nell’ambito del progetto europeo <a className="text-blue-700 underline" href="https://cordis.europa.eu/project/id/847049/it" target="_blank" rel="noopener noreferrer">HARP</a> (Horizon 2020, contratto n. 847049). <br/>
            L’adattamento è stato curato dai componenti italiani di HARP: ENEA, Assotermica ed Eurac Research.<br/>
            <Link to="/accessibilita" >Accessibilita</Link><span className="separator"></span> |  <Link to="/privacy">Informativa Privacy</Link><span className="separator"></span> 
          </p>
        </div>
      </div>

  );
}

export default FooterBottom;
