import React from "react";
import FooterBottom from "../components/FooterBottom.js";
import { Link } from 'react-router-dom';
import "../App.css";

const myStyle={
    backgroundImage: 
  "url('img/bg_login.jpg')",
    height:'auto',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
function Accessibilita() {
  return (
    <div>
      <div className="p-4 md:p-8" style={myStyle}>
        <header>
            <div className="max-w-4xl mx-auto md:mt-12 mb-8 rounded-lg shadow card">
               <section className="container px-4 my-4 ">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator"></span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Accessibilità  
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="intro" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>Accessibilità</h1>
                            <p className="mt-4">
                            Il sito è stato sviluppato in HTML5, riconosciuto recommendation dal W3C a partire dal 2021.<br/>
					    	Sono stati adottati gli accorgimenti necessari al superamento dei criteri di successo livello A e AA delle WCAG 2.1, come richiesto dalla direttiva UE 2016/2102.<br/>
						    Ulteriori modifiche tecniche per l'adeguamento completo alle specifiche dei requisiti tecnici di accessibilità dell'AgID sono in fase di svluppo.<br/><br/>

					    	Per le comunicazioni inerenti l'accessibilità è attivo l'indirizzo email <a href="mailto:accessibilita.ape@enea.it" rel="noreferrer">accessibilita.ape@enea.it</a><br/><br/>
					    	<a href="https://form.agid.gov.it/view/bec15798-9f14-44e2-8eb9-a2134bb2be11" target="_blank" rel="noreferrer">Dichiarazione di accessibilità</a>
                            </p>
                        </div>
    
                    </div>
                </section>
            </div>
        </header>
      </div>
    <FooterBottom />
    </div>
  );
};

export default Accessibilita;