import {React} from 'react';
import {FormGroup, Input, Label, FormFeedback } from 'reactstrap';

const SubstepC = props => {

  //  const [selectdManutenzione, setSelectedUManutenzione] = useState(props.state.manutenzione);
    const changeFonte = event => {
    //  setSelectedFonte(event.target.value)
        props.handleChange(event)
    }
    const changeTipo = event => {
        // setSelectedTipo(event.target.value)
        props.handleChange(event)
    }
    const changeAnno =(event,onBlur=false) => {
      //   setSelectedAnno(event.target.value)
      let { value, min, max } = event.target;
      if(onBlur){
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));
      }
      event.target.value= value;

      props.handleChange(event)
    }


    const changeManutenzione = event => {
      //    setSelectedUser(event.target.value);
          props.handleChange(event)
        }
        const changePn = event => {
    //  setSelectedPn(event.target.value)
        props.handleChange(event)
    }
    const changeN100 = event => {
    //  setSelectedN100(event.target.value)
        props.handleChange(event)
    }
    const changeN30 = event => {
    //  setSelectedN30(event.target.value)
        props.handleChange(event)
    }
    const changeElmax = event => {
    //  setSelectedElmax(event.target.value)
        props.handleChange(event)
    }
    const changeElmin = event => {
    //  setSelectedElmin(event.target.value)
        props.handleChange(event)
    }
    const changePsb = event => {
    //  setSelectedPsb(event.target.value)
        props.handleChange(event)
    }
    const changePstby = event => {
    //  setSelectedPstby(event.target.value)
        props.handleChange(event)
    }
    const changePign = event => {
    //  setSelectedPign(event.target.value)
        props.handleChange(event)
    }
    return (
    <>
     <div className="max-w-lg bg-white p-4 md:p-12 container mx-auto">
          <p className="block text-gray-700 text-2xl font-bold mb-2">{(props.state.sistema==="2")?'' : 'Dati sul servizio di riscaldamento d’ambiente'}</p>
          <FormGroup>
            <Label for="fonteenerg" className="label-std">* Fonte energetica:</Label>
            <Input
              type={"select"}
              name="fonteenerg"
              id="fonteenerg"
              value={props.state.fonteenerg??0}
              className="select-std"
              onChange={changeFonte}
              invalid={ !props.state.validate.fonteenerg }

              required
            >
              <option value="0" hidden>Scegli un’opzione</option>
              <option value={"Gas"}>gas</option>
              <option value={"Gasolio"}>gasolio</option>
              <option value={"Biomassa (pellet)"}>biomassa (pellet)</option>
              <option value={"Biomassa (cippato)"}>biomassa (cippato)</option>
            </Input>
            <FormFeedback>
              Campo obbligatorio.
            </FormFeedback>

          </FormGroup>
          <FormGroup>
            <Label for="tipocaldaia" className="label-std">* Tipo di caldaia:</Label>
            <Input
              type={"select"}
              name="tipocaldaia"
              id="tipocaldaia"
              value={props.state.tipocaldaia??0}
              className="select-std"
              onChange={changeTipo}
              invalid={ !props.state.validate.tipocaldaia }

              required
            >
              <option value="0" hidden>Scegli un’opzione</option>
              <option value={"standard"}>standard</option>
              <option value={"a bassa temperatura"}>a bassa temperatura</option>
              <option value={"a condensazione"}>a condensazione</option>
            </Input>
            <FormFeedback>
              Campo obbligatorio.
            </FormFeedback>

          </FormGroup>
          <FormGroup>
            <Label for="annoinstallazione" className="label-std">* Anno d'installazione della caldaia </Label>
            <Input
              type="number"
              name="annoinstallazione"
              id="annoinstallazione"
              min="1978"
              max={new Date().getFullYear()}
              step="1"
              onChange={changeAnno}
              onBlur={(e)=>changeAnno(e,true)}
              value={props.state.annoinstallazione??''}
              invalid={ !props.state.validate.annoinstallazione }

              required
            />

            <small id="emailHelp" className="form-text text-muted">Scegliere 1978 anche se il generatore è stato installato prima di questa data</small>

            {props.state.annoinstallazione >="2016"  && (
                  <div className="mt-2 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">HARPa è applicabile a generatori sprovvisti di etichetta energetica</div>
              )}
              <FormFeedback>
                Campo obbligatorio.
              </FormFeedback>

          </FormGroup>
          <FormGroup>
            <Label for="manutenzione" className="label-std">* Manutenzione </Label>
            Negli ultimi cinque anni l’apparecchio è stato sottoposto a manutenzione da parte di un professionista abilitato?<br/><br/>
            <Input
              type="radio"
              name="manutenzione"
              id="manutenzione_si"
              value="true"
              checked={props.state.manutenzione === 'true'}
              onChange={changeManutenzione}
              className="toggle-label-std"
            />
            <label htmlFor="manutenzione_si" className="mr-5 ml-2">Si</label>
            <Input
              type="radio"
              name="manutenzione"
              id="manutenzione_no"
              value="false"
              checked={props.state.manutenzione === 'false'}
              onChange={changeManutenzione}
            />
            <label htmlFor="manutenzione_no" className="mr-5 ml-2">No</label>
          </FormGroup>
          <div className="grid grid-cols-2 gap-2">

            <FormGroup>
              <Label for="pn" className="label-std">P<sub>n</sub> (in kW)</Label>
              <Input
                type="numeric"
                name="pn"
                id="pn"
                className="w-75"
                placeholder=""
                value={props.state.pn??''}
                onChange={changePn}
                required
              />
            </FormGroup>
          </div>
          <div className={props.state.utente==="true"?'d-none':'grid grid-cols-2 gap-2'}>
            <FormGroup>
              <Label for="n100" className="label-std">η<sub>100</sub> (da 0 a 100)</Label>
              <Input
                type="numeric"
                name="n100"
                id="n100"
                className="w-75"
                placeholder=""
                value={props.state.n100??''}
                onChange={changeN100}
              />
            </FormGroup>
            <FormGroup>
              <Label for="n30" className="label-std">η<sub>30</sub> (da 0 a 100)</Label>
              <Input
                type="numeric"
                name="n30"
                id="n30"
                className="w-75"
                placeholder=""
                value={props.state.n30??''}
                onChange={changeN30}
              />
            </FormGroup>
            <FormGroup>
              <Label for="elmax" className="label-std">el<sub>max</sub> (in kW)</Label>
              <Input
                type="numeric"
                name="elmax"
                id="elmax"
                className="w-75"
                placeholder=""
                value={props.state.elmax??''}
                onChange={changeElmax}
              />
            </FormGroup>
            <FormGroup>
              <Label for="elmin" className="label-std">el<sub>min</sub> (in kW)</Label>
              <Input
                type="numeric"
                name="elmin"
                id="elmin"
                className="w-75"
                placeholder=""
                value={props.state.elmin??''}
                onChange={changeElmin}
              />
            </FormGroup>
            <FormGroup>
              <Label for="psb" className="label-std">P<sub>SB</sub> (in kW)</Label>
              <Input
                type="numeric"
                name="psb"
                id="psb"
                className="w-75"
                placeholder=""
                value={props.state.psb??''}
                onChange={changePsb}
              />
            </FormGroup>
            <FormGroup>
              <Label for="pstby" className="label-std">P<sub>stby</sub> (in kW)</Label>
              <Input
                type="numeric"
                name="pstby"
                id="pstby"
                className="w-75"
                placeholder=""
                value={props.state.pstby??''}
                onChange={changePstby}
              />
            </FormGroup>
            <FormGroup>
              <Label for="pign" className="label-std">P<sub>ign</sub> (in kW)</Label>
              <Input
                type="numeric"
                name="pign"
                id="pign"
                className="w-75"
                placeholder=""
                value={props.state.pign??''}
                onChange={changePign}
              />
            </FormGroup>
          </div>
        </div>
    </>
  );
};

export default SubstepC;
