import React from "react";
import HarpaForm from "../components/HarpaForm";
import FooterBottom from "../components/FooterBottom.js";
import "../App.css";

const myStyle={
  backgroundImage: 
"url('img/bg_login.jpg')",
  height:'auto',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
};

function Home() {
  return (
    <div>
      <div className="p-4 md:p-8" style={myStyle}>
        <header>
          <div className="max-w-4xl mx-auto md:mt-12 mb-8 rounded-lg shadow">
            <HarpaForm />
          </div>
        </header>
      </div>
      <FooterBottom />
    </div>
  );
}

export default Home;
