import React from "react";
import FooterBottom from "../components/FooterBottom.js";
import { Link } from 'react-router-dom';
import "../App.css";

const myStyle={
    backgroundImage: 
  "url('img/bg_login.jpg')",
    height:'auto',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  };
function Privacy() {
  return (
    <div>
      <div className="p-4 md:p-8" style={myStyle}>
        <header>
            <div className="max-w-4xl mx-auto md:mt-12 mb-8 rounded-lg shadow card">
               <section className="container px-4 my-4 ">
                    <div className="row">
                        <div className="col px-lg-4">
                            <nav aria-label="breadcrumb" className="breadcrumb-container">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to="/">Home</Link><span className="separator"></span>
                                </li>
                                <li aria-current="page" className="breadcrumb-item active">
                                Informativa privacy  
                                </li>
                            </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section id="privacy" className="container px-4 my-4">
                    <div className="row">
                        <div>
                            <h1>INFORMATIVA AI SENSI DELL'ART. 13 DEL REGOLAMENTO (UE) 679/2016 RELATIVA AL TRATTAMENTO DEI DATI PERSONALI (GDPR-General Data Protection Regulation)</h1>
                            La presente informativa viene resa da ENEA - Agenzia nazionale per le nuove tecnologie, l’energia e lo sviluppo economico sostenibile, ai sensi dell'art.13 del Regolamento UE 2016/679 – Regolamento Generale sulla Protezione dei Dati, in relazione al trattamento dei dati personali degli utenti del sito web <a href="https://www.harpa.enea.it" target="_blank" rel="noreferrer">https://www.harpa.enea.it</a> (di seguito, anche “Sito”).<br/>
                            La presente informativa è resa solo per i trattamenti di dati personali degli Utenti (Interessati al trattamento) raccolti tramite il Sito.

                            <h2 className="mt-4">1. IDENTITÀ E DATI DI CONTATTO DEL TITOLARE DEL TRATTAMENTO</h2>
                            Il Titolare del trattamento è ENEA - Agenzia nazionale per le nuove tecnologie, l’energia e lo sviluppo economico sostenibile, con sede in Roma 00196, Lungotevere Thaon di Revel, 76. Può rivolgersi al Titolare del trattamento scrivendo all’indirizzo sopra riportato, o inviando un messaggio e-mail al seguente indirizzo di posta elettronica certificata: <a href="mailto:enea@cert.enea.it" rel="noreferrer">enea@cert.enea.it</a>.

                            <h2 className="mt-4">2. DATI DI CONTATTO DEL RESPONSABILE DELLA PROTEZIONE DEI DATI (RPD)</h2>
                            Il Responsabile della Protezione dei Dati (RPD) per ENEA è stato nominato con Disposizione n. 34/2020/PRES del 6 febbraio 2020.<br/>
                            Per comunicazioni è attivato il seguente indirizzo e-mail: <a href="mailto:uver.dpo@enea.it" rel="noreferrer">uver.dpo@enea.it</a>.

                            <h2 className="mt-4">3. FINALITÁ E BASE GIURIDICA</h2>
                            L'applicazione HARPa stima la classe energetica dei generatori di calore obsoleti, che sono privi di etichetta energetica. L’applicazione è dedicata agli impianti autonomi di riscaldamento e acqua calda sanitaria a uso domestico.<br/>
                            Tale applicazione è sviluppata nell’ambito delle attività del Dipartimento Unità per l’Efficienza Energetica; il Dipartimento - che svolge anche il ruolo di Agenzia Nazionale per l’Efficienza Energetica, istituita con Decreto Legislativo n. 115 del 30 maggio 2008, in recepimento della Direttiva 2006/32/CE relativa all'efficienza degli usi finali dell'energia e ai servizi energetici -, nell'esecuzione dei propri compiti di interesse pubblico o comunque connessi all'esercizio dei propri pubblici poteri, di cui lo stesso è investito, persegue le proprie finalità istituzionali espresse nello Statuto dell’ ENEA.

                            <h2 className="mt-4">4. NATURA DEL CONFERIMENTO DEI DATI </h2>
                            Il conferimento dei dati, riguardanti le caratteristiche di natura tecnica delle apparecchiature da valutare ai fini dell’attribuzione della classe energetica, attraverso la compilazione del questionario sul sito internet <a href="https://www.harpa.enea.it" target="_blank" rel="noreferrer">https://www.harpa.enea.it</a> ha natura facoltativa. 

                            <h2 className="mt-4">5. TIPI DI DATI TRATTATI</h2>
                            <h3 className="mt-4">5.1. Dati di navigazione</h3>
                            I sistemi informatici e gli applicativi dedicati al funzionamento di questo Sito web rilevano, nel corso del loro normale funzionamento, alcuni dati (la cui trasmissione è implicita nell'uso dei protocolli di comunicazione di Internet) non associati a utenti direttamente identificabili.<br/>
                            Tra i dati raccolti sono compresi gli indirizzi IP dei computer utilizzati dagli Utenti che si connettono al sito. <br/>
                            Tali dati sono trattati allo scopo di: 
                            <ul>
                            <li>assicurare la corretta funzionalità del Sito e la fruibilità del suo servizio.</li>
                            </ul>


                            <h3 className="mt-4">5.2. Dati forniti volontariamente dall'Utente</h3>
                            Altri dati, di natura non personale, raccolti sono quelli forniti dall'Utente mediante la compilazione del questionario presente sul sito <a href="https://www.harpa.enea.it" target="_blank" rel="noreferrer">https://harpa.enea.it/</a>. 

                            <h2 className="mt-4">6. MODALITÁ DEL TRATTAMENTO E PERIODO DI CONSERVAZIONE DEI DATI</h2>
                            I dati personali sono trattati con strumenti elettronici, nel rispetto di quanto previsto dall’art. 32 del GDPR 2016/679, dalle altre norme e dagli specifici Provvedimenti dell’Autorità Garante per la protezione dei dati personali in materia di misure di sicurezza.<br/>
                            ENEA adotta le misure di sicurezza necessarie previste dal GDPR per proteggere i dati raccolti, al fine di scongiurare i rischi di perdita o furto dei dati, accessi non autorizzati, usi illeciti e non corretti.<br/>
                            Le misure di sicurezza adottate, tuttavia, non consentono di escludere in via assoluta i rischi di intercettazione o compromissione dei dati personali trasmessi con strumenti telematici. Si raccomanda, pertanto, di verificare che il dispositivo in uso all’utente sia dotato di sistemi software adeguati alla protezione della trasmissione telematica di dati, sia in entrata, che in uscita (come, ad esempio, sistemi antivirus aggiornati, firewall e filtri antispam). <br/>
                            I dati oggetto di trattamento saranno conservati per un periodo di tempo non superiore a quello necessario a conseguire gli scopi per i quali essi sono stati raccolti e successivamente trattati. In particolare, i dati di navigazione saranno conservati per la durata della sessione di navigazione sul Sito.

                            <h2 className="mt-4">7. CATEGORIE DI DESTINATARI</h2>
                            Per le finalità indicate all’art. 3 i dati personali dell’Utente potranno essere comunicati o resi accessibili a:
                            <ul>
                                <li>dipendenti e collaboratori di ENEA, nella loro qualità di autorizzati al trattamento dei dati, ai sensi dell’art. 29 GDPR; </li>
                                <li>tutti i soggetti a cui la facoltà di accesso a tali dati sia riconosciuta in forza di provvedimenti normativi o autoritativi; a tutte quelle persone fisiche e/o giuridiche, pubbliche e/o private quando la comunicazione risulti necessaria o funzionale allo svolgimento dei servizi richiesti tramite il Sito, nei modi e per le finalità sopra illustrate.</li>
                            </ul>
                            Resta salva l’eventuale comunicazione dei dati su richiesta dell’autorità giudiziaria o di pubblica sicurezza, nei modi e nei casi previsti dalla legge. . 

                            <h2 className="mt-4">8. DIRITTI DEGLI INTERESSATI</h2>
                            Gli artt. 15-22 del Regolamento conferiscono all’interessato l’esercizio dei seguenti diritti:
                            <ul>
                                <li>chiedere la conferma dell’esistenza o meno dei propri dati personali (art. 15 par 1);</li>
                                <li>ottenere le indicazioni circa le finalità del trattamento, le categorie dei dati personali, i destinatari o le categorie di destinatari a cui i dati personali sono stati o saranno comunicati, e quando possibile, il periodo di conservazione (art.15 par.1 lett. a, c);</li>
                                <li>ottenere la rettifica e la cancellazione dei dati (artt. 16 e 17);</li>
                                <li>ottenere la limitazione del trattamento (art.18);</li>
                                <li>ottenere dal Titolare del trattamento informazioni sui destinatari cui sono stati trasmessi i dati personali e le eventuali rettifiche o cancellazioni o limitazioni del trattamento (art. 19); </li>
                                <li>opporsi ad un processo decisionale automatizzato relativo alle persone fisiche, compresa la profilazione (artt.21 e 22);</li>
                                <li>Gli Interessati hanno il diritto di proporre reclamo all’Autorità di controllo ai sensi dell’art. 77 del Regolamento, o di adire le opportune sedi giudiziarie.</li>
                            </ul>

                            <h2 className="mt-4">9. ESERCIZIO DEI DIRITTI</h2>
                            I diritti di cui sopra sono esercitati con richiesta rivolta al Titolare del trattamento, mediante invio di messaggio di posta elettronica privacy@enea.it oppure enea@cert.enea.it. La richiesta è formulata liberamente e senza formalità dall’interessato, che ha diritto di riceverne idoneo riscontro entro un termine ragionevole, in funzione delle circostanze del caso.<br/>
                            L’Interessato può avvalersi, per l’esercizio dei suoi diritti, di organismi, organizzazioni o associazioni senza scopo di lucro, i cui obiettivi statutari siano di pubblico interesse e che siano attivi nel settore della protezione dei diritti e delle libertà degli Interessati con riguardo alla protezione dei dati personali, conferendo, a tal fine, idoneo mandato. L’Interessato può, altresì, farsi assistere da una persona di fiducia.<br/>
                            È possibile ricevere maggiori informazioni sulle finalità e sulle modalità di trattamento dei dati personali, scrivendo all’indirizzo e-mail privacy@enea.it oppure efficienzaenergetica@enea.it e indicando nell’oggetto “Privacy-Harpa-efficienzaenergetica”.<br/>
                            Per conoscere i propri diritti, proporre un reclamo/segnalazione/ricorso ed essere sempre aggiornato sulla normativa in materia di tutela delle persone rispetto al trattamento dei dati personali, l’Interessato può rivolgersi all’Autorità Garante per la protezione dei dati personali, consultando il sito web all'indirizzo <a href="http://www.garanteprivacy.it/" target="_blank" rel="noreferrer">http://www.garanteprivacy.it</a>.
                            <br/><br/>

                            L’adattamento è stato curato dai componenti italiani di HARP: ENEA, Assotermica ed Eurac Research.

                            <h1 className="mt-4">INFORMATIVA COOKIE</h1>
                            <h2 className="mt-4">1.	Che cos'è un cookie e a cosa serve?</h2>
                            Un cookie è un file di piccole dimensioni che viene inviato al browser e salvato sul dispositivo dell'utente (PC,Tablet ecc..), quando accede e utilizza il sito web <a href="https://www.harpa.enea.it" target="_blank" rel="noreferrer">https://www.harpa.enea.it</a> (di seguito, anche solo “Sito”). <br/>
                            I cookie permettono un funzionamento efficiente del Sito e ne migliorano le prestazioni, anche mediante la raccolta e l'analisi di informazioni aggregate, che consentono al gestore del Sito di comprendere come migliorare la struttura del Sito e delle sue sezioni. Mediante i cookie, inoltre, è possibile raccogliere informazioni per fini statistici, principalmente per personalizzare l'esperienza di navigazione dell'utente, ricordando le sue preferenze (per esempio, la lingua, la valuta etc.).<br/>
                            I cookie sono classificati in base alla durata e al sito che li ha impostati, quindi a seconda dei singoli obiettivi, si utilizzano cookie differenti.<br/><br/>
                            <h2>2.	Quali cookie utilizziamo?</h2>
                            Nel Sito vengono utilizzati cookie e altri identificatori tecnici prime parti.
                            
                            <h3 className="mt-4">Cookie tecnici</h3>
                            I cookie tecnici possono essere suddivisi in cookie di navigazione o di sessione, che garantiscono la normale navigazione e fruizione del Sito (permettendo, ad esempio, di coordinare i moduli che compongono le pagine del Sito o correlare le pagine visitate alla medesima sessione di lavoro) e  cookie analytics, assimilabili ai cookie tecnici soltanto se utilizzati a fini di ottimizzazione del Sito direttamente dal gestore del Sito stesso (c.d. cookie analitici di prima parte) che potrà raccogliere informazioni in forma aggregata sul numero degli utenti e su come questi visitano il Sito (ad esempio, per realizzare statistiche delle pagine più visitate, per raccogliere i dati aggregati delle visite per sistema operativo, per browser, etc.).
                            <h3 className="mt-4">Cookie di sessione</h3>
                            L'uso dei cookie di sessione è strettamente limitato alla trasmissione di identificativi di sessione (costituiti da numeri casuali generati dal server) e di dati di utilizzo del software necessari per consentire l'esplorazione sicura ed efficiente del sito: le variabili (c.d. cookie) di sessione possono evitare il ricorso a tecniche informatiche potenzialmente pregiudizievoli per la riservatezza della navigazione degli utenti e non consentono l'acquisizione di dati personali identificativi dell'utente. I cookie di sessione non vengono memorizzati in modo persistente sul dispositivo dell'utente (PC,Tablet ecc..), ma vengono registrati localmente per periodi di tempo stabiliti in funzione delle variabili di sessione (i cui valori memorizzati sul disco fisso del dispositivo possono comunque essere cancellati; è inoltre possibile disabilitare i cookie seguendo le indicazioni fornite dai principali browser).  I cookie di sessione rimangono memorizzati nel dispositivo per un breve periodo di tempo e si eliminano quindi alla chiusura del browser Web.
                            <h3 className="mt-4">Cookie permanenti</h3>
                            I cookie permanenti salvano un file nella memoria del vostro dispositivo (PC,Tablet ecc..) per un lungo periodo di tempo. Questo tipo di cookie possiede una data di scadenza. I cookie permanenti consentono ai siti Web di ricordare informazioni e impostazioni alle successive visite degli utenti, rendendo così l'esplorazione più pratica e rapida. Alla data di scadenza, il cookie viene automaticamente eliminato quando si effettua il primo accesso al sito Web che lo ha creato.
                            <h2 className="mt-4">3.	Dettaglio dei cookie utilizzati</h2>
                            La spiegazione riportata di seguito illustra più dettagliatamente i cookie utilizzati nel nostro sito e i relativi scopi per cui vengono utilizzati.<br/>
                            Necessari (1)<br/>
                            I cookie necessari aiutano a contribuire a rendere fruibile un sito web abilitando le funzioni di base come la navigazione della pagina e l'accesso alle aree protette del sito. Il sito web non può funzionare correttamente senza questi cookie.<br/>

                            <table className="responsive-table mb-3 mt-3 w-100">
                              <thead>
                                <tr>
                                  <td>
                                    <p><strong>NOME</strong></p>
                                  </td>
                                  <td>
                                    <p><strong>FORNITORE</strong></p>
                                  </td>
                                  <td>
                                    <p><strong>SCOPO</strong></p>
                                  </td>
                                  <td>
                                    <p><strong>SCADENZA</strong></p>
                                  </td>
                                  <td>
                                    <p><strong>TIPO</strong></p>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <p>displayCookieConsent</p>
                                  </td>
                                  <td>
                                    <p>www.harpa.enea.it</p>
                                  </td>
                                  <td>
                                    <p>Regola il banner cookies</p>
                                  </td>
                                  <td>
                                    <p>5 mesi</p>
                                  </td>
                                  <td>
                                    <p>HTTP Cookie</p>
                                  </td>
                                </tr>
                               
                              </tbody>
                            </table>

                            <h2 className="mt-4">4.	Per quanto tempo conserviamo i tuoi dati?</h2>
                            I dati sono conservati per il tempo indicato nella tabella di cui sopra, nel presente documento. 
                            I dati di navigazione raccolti dal sito vengono conservati per il tempo necessario a consentire la navigazione del sito stesso e comunque per il tempo indicato nella tabella di cui sopra.

                            <h2 className="mt-4">5.	Come posso disabilitare i cookie?</h2>
                            La maggior parte dei browser è configurata per accettare, controllare o eventualmente disabilitare i cookie attraverso le impostazioni. Si ricorda, però, che la disabilitazione, anche parziale, dei cookie tecnici potrebbe compromettere il funzionamento del Sito e/o limitarne le funzionalità.<br/>
                            La disabilitazione dei cookie di terza parte, invece, non pregiudica in alcun modo la navigabilità.
                            Si indicano, di seguito, i link alle istruzioni fornite dai principali browser di navigazione per la gestione delle impostazioni sull'installazione dei cookie:<br/><br/>
                            Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=it" target="_blank" rel="noreferrer">https://support.google.com/chrome/answer/95647?hl=it</a><br/>
                            Firefox: <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie" target="_blank" rel="noreferrer">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a><br/>
                            Internet Explorer: <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies" target="_blank" rel="noreferrer">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies</a><br/>
                            Opera: <a href="http://help.opera.com/Windows/10.00/it/cookies.html" target="_blank" rel="noreferrer">http://help.opera.com/Windows/10.00/it/cookies.html</a><br/>
                            Safari:  <a href="https://support.apple.com/it-it/guide/safari/sfri11471/mac" target="_blank" rel="noreferrer">https://support.apple.com/it-it/guide/safari/sfri11471/mac</a> <br/>
                            Microsoft Edge: <a href="https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09" target="_blank" rel="noreferrer">https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09</a> <br/><br/>

                        </div>
    
                    </div>
                </section>
            </div>
        </header>
      </div>
    <FooterBottom />
    </div>
  );
};

export default Privacy;