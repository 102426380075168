import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FormGroup, Input, Label, FormFeedback } from "reactstrap";
import filepdf from '../doc/D3.1-Labelling-methodologies-and-validation-report.pdf';

const Step1 = props => {

 // Popover open state
 //const [popoverOpen, setPopoverOpen] = React.useState(false);
 const [items, setItems] = useState([]);
// const [selectedRegione, setSelectedRegione] = useState();

  useEffect( () => {
      fetchItems();
    //  if(props.state.comuni){
      //  setItemsComuni(props.state.comuni);

  //    }

}, [props.state.regione]);
  const [itemsComuni, setItemsComuni] = useState([]);

  //const [selectedComune, setSelectedComune] = useState();
  //const [setSelectedSistema] = useState();
  //const [selectedUser, setSelectedUser] = useState("true");
  const fetchItemsComuni = async (regione) => {
      const data = await fetch(`/api/comuni/${regione}`);
      const items = await data.json();
      setItemsComuni(items);
  };
  const fetchItems = async () => {
      const data = await fetch('/api/regioni');
      const items = await data.json();
      setItems(items);
      if(props.state.regione){
        fetchItemsComuni(props.state.regione)
      }
  };


  const changeRegione = event => {
 //   setSelectedRegione(event.target.value)
    fetchItemsComuni(event.target.value)
    props.handleChange(event)
  }
  const changeSistema = event => {
   // setSelectedSistema(event.target.value)
    props.handleChange(event)
  }
  const changeComune = event => {
  //  setSelectedComune(event.target.value)
    props.handleChange(event)
  };
  const changeUtente = event => {
//    setSelectedUser(event.target.value);
    props.handleChange(event)
  };

  //const [tooltipOpen, setTooltipOpen] = React.useState(false);
  // if (props.currentStep !== 1) {
  //   return null;
  // }

    return (
    <>
    <div id="pre_questions" className="max-w-xl bg-white p-4 md:p-12 container mx-auto">
    <div className="bg-gray-100 text-gray-700 text-xl p-4">
      <p className="mb-2">HARPa stima la classe energetica dei generatori di calore obsoleti, che sono privi di etichetta energetica. L’applicazione è dedicata agli impianti autonomi di riscaldamento e acqua calda sanitaria a uso domestico.</p>
      <p className="mb-2">Clicca <a href={filepdf} target="_blank"  rel="noopener noreferrer">QUI</a> e <a href="https://www.mdpi.com/1996-1073/14/21/7044" target="_blank"  rel="noopener noreferrer">QUI</a> per informazioni sul metodo di calcolo e sulla sua validazione.</p>
    </div>
      <FormGroup>
        <Label for="sistema" className="label-std">Tipo di generatore</Label>
          <Input
            type={"select"}
            name="sistema"
            id="sistema"
            className="select-std"
            value={props.state.sistema}
            onChange={changeSistema}
            invalid={ !props.state.validate.sistema }

            required
          >
          <option  disabled="disabled" value="0">Scegli un’opzione</option>
          <option value="1">Scaldaacqua</option>
          <option value="2">Caldaia per riscaldamento d'ambiente</option>
          <option value="3">Generatore a uso combinato</option>
          </Input>
          <FormFeedback>
            Campo obbligatorio.
          </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="regione" className="label-std">Regione in cui si trova l’immobile</Label>
          <Input
            type={"select"}
            name="regione"
            id="regione"
            className="select-std"
            value={props.state.regione}
            onChange={changeRegione}
            invalid={ !props.state.validate.regione }

            required
          >
          <option disabled="disabled" value="0">Scegli un’opzione</option>
              {
              items.map(item => (
                  <option key={item.ID} value={item.ID}>{item.Regione}</option>
              ))
              }
          </Input>
          <FormFeedback>
            Campo obbligatorio.
          </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="comune" className="label-std">Comune</Label>
          <Input
            type={"select"}
            name="comune"
            id="comune"
            className="select-std"
          //  Value={props.state.comune}
            value={props.state.comune??0}
            onChange={changeComune}
            invalid={ !props.state.validate.comune }
            required
          >
          <option disabled="disabled" value="0">Scegli un’opzione</option>
              {
              itemsComuni.map(item => (
                  <option key={item.id} value={item.id}>{item.comune}</option>
              ))
              }
          </Input>
          <FormFeedback>
            Campo obbligatorio.
          </FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label className="label-std">Quale versione vuoi utilizzare? </Label>
            <Input
              type="radio"
              name="utente"
              id="utente_si"
              value="true"
              checked={props.state.utente==="true"}
              invalid={ !props.state.validate.utente }
              onChange={changeUtente}
            />
            <label htmlFor="utente_si" className="mr-5 ml-2">Base</label>
            <Input
              type="radio"
              name="utente"
              id="utente_no"
              value="false"
              checked={props.state.utente==="false"}
              invalid={ !props.state.validate.utente }
              onChange={changeUtente}
            />
            <label htmlFor="utente_no" className="mr-5 ml-2">Avanzata (per professionisti)</label>
            <FormFeedback>
              Campo obbligatorio.
            </FormFeedback>
      </FormGroup>
      </div>
    </>
  );
};

export default Step1;
