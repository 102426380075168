import React, { Component } from "react";
import {
  Form,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  CardFooter
} from "reactstrap";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Cookiebar from './Cookiebar';
import MultiStepProgressBar from "./MultiStepProgressBar";

import { jsPDF } from "jspdf";
import logo_harpa from '../img/logo_harpa.png';
import logo_enea from '../img/logo_enea.png';
import logo_assotermica from '../img/logo_assotermica.png'; // with import
import logo_eurac from '../img/logo_eurac.png'; // with import




class Harpaform extends Component {

  constructor(props) {

    super(props);


    // if(localStorage.getItem("valoriutente") && window.confirm('Vuoi ricaricare gli ultimi dati salvati?')){
    //   let store=JSON.parse(localStorage.getItem("valoriutente"))??''
    //
    //
    //   // Set the intiial input values
    //   this.state = {
    //     currentStep: 1,
    //     utente: store.utente,
    //     sistema: store.sistema,
    //     regione: store.regione,
    //     comune: store.comune,
    //     fonteenerg: store.fonteenerg,
    //     tipocaldaia: store.tipocaldaia,
    //     annoinstallazione: store.annoinstallazione,
    //     manutenzione: store.manutenzione,
    //     tiposcaldaacqua: store.tiposcaldaacqua,
    //     numerofruitori: store.numerofruitori,
    //     tabellac: false,
    //     tabellam: false,
    //     validate: {
    //       sistema: true,
    //       regione: true,
    //       comune: true,
    //       utente: true,
    //     },
    //
    //
    //
    //   };
    //
    //
    // }else{
      // Set the intiial input values
      this.state = {
        currentStep: 1,
        utente: "true",
        sistema: 0,
        regione: 0,
        fonteenerg: "",
        tipocaldaia: "0",
        tiposcaldaacqua: "0",
        annoinstallazione: "",
        manutenzione: "true",
        scaldaacquaelettrico: "Verticale",
        tabellac: false,
        tabellam: false,
        validate: {
          // step1
          sistema: true,
          regione: true,
          comune: true,
          utente: true,
          // step2
          fonteenerg: true,
          numerofruitori: true,
          profilocarico: true,
          tipocaldaia: true,
          annoinstallazione: true,
          manutenzione: true,
          tiposcaldaacqua: true,

        },

      // }
    }

    // Bind the submission to handleChange()
    this.handleChange = this.handleChange.bind(this);
    this.setValue = this.setValue.bind(this);
    this.caricaValori = this.caricaValori.bind(this);

    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
  }

  toPdf = (event) => {
    const doc = new jsPDF();
        doc.addImage('img/logo.jpg', 'JPEG', 20, 20, 51, 18)
        doc.addImage('img/logo_enea.png', 'PNG', 100, 280, 22, 7)
        doc.addImage('img/logo_eurac.png', 'PNG', 180, 280, 20, 7)
        doc.addImage('img/logo_assotermica.png', 'PNG', 20, 280, 28, 7)
        doc.setFontSize(25)
        doc.setFont(undefined, 'bold');
        doc.text(20,50, ((this.state.sistema==="1")?'Scaldaacqua' : (this.state.sistema==="2")?'Caldaia per riscaldamento d\'ambiente' : 'Generatore a uso combinato' ))
        doc.setFont(undefined, 'bold');
        doc.setFontSize(16)
        doc.text(20,57, ((this.state.sistema==="2")?'di tipo ' + this.state["tipocaldaia"] : (this.state.sistema==="1")?'di tipo ' + this.state["tiposcaldaacqua"] : (this.state.sistema==="3")?'Caldaia di tipo ' + this.state["tipocaldaia"] + ' (a.c.s. '  + this.state["tiposcaldaacqua"] + ')': ''))
        
        let text = 'Fonte energetica: ' + this.state.fonteenerg
        let lineHeight = doc.getLineHeight(text) / doc.internal.scaleFactor
        let splittedText = doc.splitTextToSize(text, 190)
        let lines = splittedText.length  // splitted text is a string array
        let blockHeight = lines * lineHeight
        let yPos = 67
        let xPos = 20

        doc.setFont(undefined, 'normal');
        doc.setFontSize(12)
        if(this.state.sistema==="2"){
          doc.text(xPos, yPos, splittedText)
          yPos += blockHeight
          doc.text(xPos, yPos, 'Anno d\'installazione: ' + this.state.annoinstallazione)
 
          if(this.state.pn){
            yPos += lineHeight
            doc.text(xPos, yPos, 'Pn: ' + this.state.pn + ' kW')
          }
          if(this.state.n100){
            yPos += lineHeight
            doc.text(xPos, yPos, 'n100: ' + this.state.n100)
          }
          if(this.state.n30){
            yPos += lineHeight
            doc.text(xPos, yPos, 'n30: ' + this.state.n30)
          }
          if(this.state.elmax){
            yPos += lineHeight
            doc.text(xPos, yPos, 'elmax: ' + this.state.elmax + ' kW')
          }
          if(this.state.elmin){
            yPos += lineHeight
            doc.text(xPos, yPos, 'elmin: ' + this.state.elmin + ' kW')
          }
          if(this.state.psb){
            yPos += lineHeight
            doc.text(xPos, yPos, 'PSB: ' + this.state.psb + ' kW')
          }
          if(this.state.pstby){
            yPos += lineHeight
            doc.text(xPos, yPos, 'Pstby: ' + this.state.pstby + ' kW')
          }
          if(this.state.pign){
            yPos += lineHeight
            doc.text(xPos, yPos, 'Pign: ' + this.state.pign + ' kW')
          }
          if(this.state.efficienzacaldaia<"36"){
            yPos += lineHeight
            doc.text(xPos, yPos, 'L\'efficienza energetica stimata è inferiore al 36%')
          } else{
            yPos += lineHeight
            doc.text(xPos, yPos, 'Efficienza energetica stimata: ' + this.state.efficienzacaldaia + '%')
          }
          yPos += lineHeight
          doc.text(xPos, yPos, 'Classe energetica stimata: ' + this.state.classeenergeticacaldaia)
          yPos += lineHeight
          if(this.state.efficienzacaldaia>="36"){
            doc.addImage('img/etichette/' +[`etichetta_C_${this.state.etichettaclasseenergeticacaldaia}`] + '.jpg', 'JPEG', 70, yPos, 80, 85)
            }  
        }

        if(this.state.sistema==="3"){
            doc.text(xPos, yPos, splittedText)
            yPos += blockHeight
            doc.text(xPos, yPos, 'Anno d\'installazione: ' + this.state.annoinstallazione)


            if(this.state.pn){
              yPos += lineHeight
              doc.text(xPos, yPos, 'Pn: ' + this.state.pn + ' kW')
            }
            if(this.state.n100){
              yPos += lineHeight
              doc.text(xPos, yPos, 'n100: ' + this.state.n100)
            }
            if(this.state.n30){
              yPos += lineHeight
              doc.text(xPos, yPos, 'n30: ' + this.state.n30)
            }
            if(this.state.elmax){
              yPos += lineHeight
              doc.text(xPos, yPos, 'elmax: ' + this.state.elmax + ' kW')
            }
            if(this.state.elmin){
              yPos += lineHeight
              doc.text(xPos, yPos, 'elmin: ' + this.state.elmin + ' kW')
            }
            if(this.state.psb){
              yPos += lineHeight
              doc.text(xPos, yPos, 'PSB: ' + this.state.psb + ' kW')
            }
            if(this.state.pstby){
              yPos += lineHeight
              doc.text(xPos, yPos, 'Pstby: ' + this.state.pstby + ' kW')
            }
            if(this.state.pign){
              yPos += lineHeight
              doc.text(xPos, yPos, 'Pign: ' + this.state.pign + ' kW')
            }
            if(this.state.profilocarico){
              yPos += lineHeight
              doc.text(xPos, yPos, 'Profilo di carico: ' + this.state.profilocarico)
            }
            if(this.state.volumeaccumulo){
              yPos += lineHeight
              doc.text(xPos, yPos, 'Volume d\'accumulo: ' + this.state.volumeaccumulo + ' L')
            }
            if(this.state.efficienzacaldaia<"36"){
              yPos += lineHeight
              doc.text(xPos, yPos, 'L\'efficienza energetica stimata è inferiore al 36%')
            } else{
              yPos += lineHeight
              doc.text(xPos, yPos, 'Efficienza energetica stimata (caldaia per riscaldamento d\'ambiente): ' + this.state.efficienzacaldaia + '%')
            }
            yPos += lineHeight
            doc.text(xPos, yPos, 'Classe energetica stimata (caldaia per riscaldamento d\'ambiente): ' + this.state.classeenergeticacaldaia)
              if(this.state.efficienzascaldaacqua < this.state.qvalue){
                yPos += lineHeight
                doc.text(xPos, yPos, 'Non è possibile stimare la classe energetica del generatore. Si prega di verificare i dati inseriti')
              } else{
                  yPos += lineHeight
                doc.text(xPos, yPos, 'Efficienza energetica stimata (scaldaacqua): ' + this.state.efficienzascaldaacqua + '%')
              }
            yPos += lineHeight
            doc.text(xPos, yPos, 'Classe energetica stimata (scaldaacqua): ' + this.state.classeenergeticascaldaacqua)
            yPos += lineHeight
            let xposition=70
            if(this.state.efficienzacaldaia>="36"){
              xposition=xPos+100
              if(this.state.efficienzascaldaacqua >= this.state.qvalue){
                doc.addImage('img/etichette/' +[`etichetta_C_${this.state.etichettaclasseenergeticacaldaia}`] + '.jpg', 'JPEG', xPos, yPos, 80, 85)
              } else{
                doc.addImage('img/etichette/' +[`etichetta_C_${this.state.etichettaclasseenergeticacaldaia}`] + '.jpg', 'JPEG', 70, yPos, 80, 85)
              }
            }
            if(this.state.efficienzascaldaacqua >= this.state.qvalue){
              doc.addImage('img/etichette/' +[`etichetta_S_${this.state.etichettaclasseenergeticascaldaacqua}`] + '.jpg', 'JPEG', xposition, yPos, 80, 85)
            }
        }
        
        if(this.state.sistema==="1"){
          doc.text(xPos, yPos, splittedText)
          yPos += blockHeight
          doc.text(xPos, yPos, 'Anno d\'installazione: ' + this.state.annoinstallazione)
         
          if(this.state.profilocarico){
            yPos += lineHeight
            doc.text(xPos, yPos, 'Profilo di carico: ' + this.state.profilocarico)
          }
          if(this.state.pn){
            yPos += lineHeight
            doc.text(xPos, yPos, 'Pn: ' + this.state.pn)
          }
          if(this.state.volumeaccumulo){
            yPos += lineHeight
            doc.text(xPos, yPos, 'Volume d\'accumulo: ' + this.state.volumeaccumulo + ' L')
          }
          if(this.state.n100){
            yPos += lineHeight
            doc.text(xPos, yPos, 'n100: ' + this.state.n100)
          }
          if(this.state.elmax){
            yPos += lineHeight
            doc.text(xPos, yPos, 'elmax: ' + this.state.elmax + ' kW')
          }
          if(this.state.psb){
            yPos += lineHeight
            doc.text(xPos, yPos, 'PSB: ' + this.state.psb + ' kW')
          }
          
          if(this.state.efficienzascaldaacqua < this.state.qvalue || this.state.novalue){
            yPos += lineHeight
            doc.text(xPos, yPos, 'Non è possibile stimare la classe energetica del generatore. Si prega di verificare i dati inseriti')
          } else{
            yPos += lineHeight
            doc.text(xPos, yPos, 'Efficienza energetica stimata: ' + this.state.efficienzascaldaacqua + '%')
            yPos += lineHeight
            doc.text(xPos, yPos, 'Classe energetica stimata: ' + this.state.classeenergeticascaldaacqua)
          }
          if(this.state.efficienzascaldaacqua >= this.state.qvalue){
            yPos += lineHeight
            doc.addImage('img/etichette/' +[`etichetta_S_${this.state.etichettaclasseenergeticascaldaacqua}`] + '.jpg', 'JPEG', 70, yPos, 80, 85)
          }
        }

        doc.save('etichetta.pdf')

  };


  // Use the submitted data to set the state
  handleChange(event) {

    if(event.target.type==='checkbox'){
      const { name, checked } = event.target;
      this.setState({
        [name]: checked
      });

    }else{
      const { name, value } = event.target;
      this.setState({
        [name]: value
      });
      const validate=this.state.validate
      if(event.target.required && value===''){
        validate[name] = false;

      }else{
        validate[name] = true;

      }
    }

  }
  caricaValori(event) {
    let store=JSON.parse(localStorage.getItem("valoriutente"))??''
    //console.log(this.state);

    // Set the intiial input values
    this.setState({
      utente: store.utente,
      sistema: store.sistema,
      regione: store.regione,
      comune: store.comune,
      fonteenerg: store.fonteenerg,
      tipocaldaia: store.tipocaldaia,
      annoinstallazione: store.annoinstallazione,
      manutenzione: store.manutenzione,
      tiposcaldaacqua: store.tiposcaldaacqua,
      numerofruitori: store.numerofruitori,
      tabellac: false,
      tabellam: false,
    })
    
  //  console.log(this.state);

  }
  resetButton = (event) => {
    event.preventDefault();
    this.setState({ utente: "true", sistema: 0, regione: 0, comune: undefined});
  }
  resetButton2 = (event) => {
    event.preventDefault();
   // this.setState({ fonteenerg: "", tiposcaldaacqua: "0", tipocaldaia: "0", annoinstallazione: "", numerofruitori: "", profilocarico: "", pn: "", scaldaacquaelettrico: "Verticale", volumeaccumulo: "", n100: "", n30: "", elmax: "", elmin: "", psb: "", pstby: "", pign: "", manutenzione: "true"});
    this.setState({ fonteenerg: "", tiposcaldaacqua: "0", tipocaldaia: "0", annoinstallazione: "", numerofruitori: "", profilocarico: "", scaldaacquaelettrico: "Verticale", volumeaccumulo: "", manutenzione: "true", pn: undefined, n100: undefined, n30: undefined, elmax: undefined, elmin: undefined, psb: undefined, pstby: undefined, pign: undefined});
  }

  setValue(obj) {
      const { name, value } = obj;
      this.setState({
        [name]: value
      });

  }

  // Trigger an alert on form submission
  handleSubmit = (event) => {
    event.preventDefault();
    const { utente, regione, comune, sistema } = this.state;
    alert(`Your registration detail: \n
      Utente: ${utente} \n
      regione: ${regione} \n
      comune: ${comune} \n
      sistema: ${sistema}`);
  };

  // Test current step with ternary
  // _next and _previous functions will be called on button click
  _next() {
    let currentStep = this.state.currentStep;
    // controllo i campi obbligatori per il primo step

    if(currentStep===1){
      this.setState({
        tabellac: false,
        tabellam: false
      });
      const { utente, comune, regione, sistema } = this.state;
      if(utente===""){
        this.setState(prevState => ({
          validate: {
                ...prevState.validate,
                utente: false
          }
         }))
      }else{
        this.setState(prevState => ({
          validate: {
                ...prevState.validate,
                utente: true
          }
         }))
      }
      if(comune===undefined){
        this.setState(prevState => ({
          validate: {
                ...prevState.validate,
                comune: false
          }
         }))
      }else{
        this.setState(prevState => ({
          validate: {
                ...prevState.validate,
                comune: true
          }
         }))
      }
      if(regione===0){
        this.setState(prevState => ({
          validate: {
                ...prevState.validate,
                regione: false
          }
         }))
      }else{
        this.setState(prevState => ({
          validate: {
                ...prevState.validate,
                regione: true
          }
         }))
      }
      if(sistema===0){
        this.setState(prevState => ({
          validate: {
                ...prevState.validate,
                sistema: false
          }
         }))
      }else{
        this.setState(prevState => ({
          validate: {
                ...prevState.validate,
                sistema: true
          }
         }))
      }


      if(utente==="" || regione==="" || sistema===0 || comune===undefined ){
        //alert('Tutti i campi sono obbligatori');
        return;
      }
    }
    if(currentStep===2){
      this.setState({
        tabellac: false,
        tabellam: false

      });
      const { utente,sistema,fonteenerg, tipocaldaia,  manutenzione, tiposcaldaacqua, annoinstallazione, numerofruitori, profilocarico } = this.state;

      if(sistema==="1"){
        let valido=true;
        if(fonteenerg==="" || tiposcaldaacqua==="0" || annoinstallazione===""){
          if(fonteenerg===""){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    fonteenerg: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    fonteenerg: false
              }
             }))
          }
          if(tiposcaldaacqua==="0"){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    tiposcaldaacqua: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    tiposcaldaacqua: true
              }
             }))
          }
          if(annoinstallazione===""){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    annoinstallazione: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    annoinstallazione: true
              }
             }))
          }
          valido=false;
        }

        if((utente==="true" && (numerofruitori==="" || !numerofruitori))){
          this.setState(prevState => ({
            validate: {
                  ...prevState.validate,
                  numerofruitori: false
            }
           }))
            valido=false;
        }
        if((utente==="false" && (profilocarico==="0" || !profilocarico) )){
          this.setState(prevState => ({
            validate: {
                  ...prevState.validate,
                  profilocarico: false
            }
           }))
          valido=false;
        }
        if(!valido) return;
      }
      if(sistema==="2"){
        if(fonteenerg==="" || tipocaldaia==="0" || annoinstallazione===""){
          if(fonteenerg===""){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    fonteenerg: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    fonteenerg: true
              }
             }))
          }
          if(tipocaldaia==="0"){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    tipocaldaia: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    tipocaldaia: true
              }
             }))
          }
          if(annoinstallazione===""){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    annoinstallazione: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    annoinstallazione: true
              }
             }))
          }

          return;
        }

      }
      if(sistema==="3"){
        let valido=true;
        // if(fonteenerg==="" || manutenzione==="" || tiposcaldaacqua==="0" || tipocaldaia==="0" || annoinstallazione==="" || numerofruitori==="" || !numerofruitori){
        if(fonteenerg==="" || manutenzione==="" || tiposcaldaacqua==="0" || tipocaldaia==="0" || annoinstallazione===""){
          if(fonteenerg===""){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    fonteenerg: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    fonteenerg: true
              }
             }))
          }
          if(tipocaldaia==="0"){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    tipocaldaia: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    tipocaldaia: true
              }
             }))
          }
          if(tiposcaldaacqua==="0"){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    tiposcaldaacqua: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    tiposcaldaacqua: true
              }
             }))
          }
          if(annoinstallazione===""){
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    annoinstallazione: false
              }
             }))
          }else{
            this.setState(prevState => ({
              validate: {
                    ...prevState.validate,
                    annoinstallazione: true
              }
             }))
          }
          // if(numerofruitori===""){
          //   this.state.validate.numerofruitori=false
          // }else{
          //   this.state.validate.numerofruitori=true
          // }

          valido=false;
        }
        if((utente==="true" && (numerofruitori==="" || !numerofruitori))){
          this.setState(prevState => ({
            validate: {
                  ...prevState.validate,
                  numerofruitori: false
            }
           }))
          valido=false;
        }
        if((utente==="false" && (profilocarico==="0" || !profilocarico) )){
          this.setState(prevState => ({
            validate: {
                  ...prevState.validate,
                  profilocarico: false
            }
           }))
          valido=false;
        }
        if(!valido) return; 
        }
    }
    // If the current step is 1 or 2, then add one on "next" button click
    currentStep = currentStep >= 2 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
  }

  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
  }




  // The "next" and "previous" button functions
  get previousButton() {
    let currentStep = this.state.currentStep;

    // If the current step is not 1, then render the "previous" button
    if (currentStep !== 1) {
      return (
        <Button color="primary float-left" onClick={this._prev}>
          Indietro
        </Button>
      );
    }

    // ...else return nothing
    return null;
  }

  get nextButton() {
    let currentStep = this.state.currentStep;
    // If the current step is not 3, then render the "next" button
    if (currentStep < 2) {
      return (
        <><Button color="primary float-right" onClick={this._next}>
          Avanti
        </Button>
        {/* Se ci sono dati salvati aggiungi anche questo bottone e quello sopra diventa "Inizia da zero" */
        (localStorage.getItem("valoriutente")&& (<Button color="primary float-left" onClick={this.caricaValori}>Ricarica i dati salvati</Button>))
        }
        <Button color="warning float-left ml-2" onClick={this.resetButton}>
          Pulisci
        </Button>

        </>
      );
    }else if(currentStep < 3) {
        return (
          <>  
           <Button color="warning float-left ml-2" onClick={this.resetButton2}>
          Pulisci
           </Button>
          <Button color="primary float-right" onClick={this._next}>
            Avanti
          </Button>
          </>
        );
      }

    // ...else render nothing
    return null;
  }

  get submitButton() {
    let currentStep = this.state.currentStep;

    // If the current step is the last step, then render the "submit" button
    if (currentStep > 2) {
      return <Button color="primary float-right" onClick={this.toPdf}>Stampa etichetta</Button>;
    }
    // ...else render nothing
    return null;
  }


  render() {
    //const currentStep = this.state.currentStep;
    return (
      <>
        <Cookiebar />
        <div className="max-w-full bg-grey-700 p-3 md:p-8 rounded-t-lg">
         <div className="grid grid-rows-3 grid-flow-col">
              <div className="row-span-3 col-span-12"><img src={logo_harpa} className="w-20" alt="HARP"/></div>
              <div className="row-span-3 col-span-12 titlebanner">HARPa</div>
              <div className="row-span-1 col-span-1"><img src={logo_enea} className="w-20" alt="ENEA" /></div>
              <div className="row-span-1 col-span-1 pt-2"><img src={logo_assotermica} className="w-20" alt="Assotermica" /></div>
              <div className="row-span-1 col-span-1"><img src={logo_eurac} className="w-20" alt="EURAC" /></div>
          </div>
        </div>

        <Form onSubmit={this.handleSubmit}>
          <Card className="p-6">
            <CardBody>
              <CardTitle>
                <MultiStepProgressBar currentStep={this.state.currentStep} />
              </CardTitle>
              <CardText />
              {(this.state.currentStep === 1) &&

              <Step1
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                caricaValori={this.caricaValori}
                state={this.state}
              />
              }
              {(this.state.currentStep === 2) &&

              <Step2
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                state={this.state}
              />
              }
              {(this.state.currentStep === 3) &&
              <Step3
                currentStep={this.state.currentStep}
                handleChange={this.handleChange}
                setValue={this.setValue}
                state={this.state}
              />}
            </CardBody>
            <CardFooter>
              {this.previousButton}
              {this.nextButton}
              {this.submitButton}
            </CardFooter>
          </Card>
        </Form>
      </>
    );
  }
}

export default Harpaform;
