import {React} from "react";
import { Link } from 'react-router-dom';
import CookieConsent from "react-cookie-consent";


function Cookiebar() {

    return (
            <div>
                <CookieConsent
                location="bottom"
                buttonText="Ho capito"
                cookieName="displayCookieConsent"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                >
                Noi di ENEA non usiamo alcuna tecnica di profilazione degli utenti, ma solo cookies tecnici necessari al corretto funzionamento del sito. Per saperne di più leggi {" "}
                <Link to="/privacy" style={{pointerEvents: 'none', textDecoration: 'none'}}><span className="text-white underline">l'infomativa</span></Link>.
                </CookieConsent>
            </div>
    );
  };
  
  export default Cookiebar;