import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import Accessibilita from './pages/accessibilita';
import Privacy from './pages/privacy';
import './App.css';

class App extends Component {

render() {
    return (
       <BrowserRouter>
            <Routes>
             <Route path="/" element={<Home />} />
             <Route path="/accessibilita" element={<Accessibilita />} />
             <Route path="/privacy" element={<Privacy />} />
           </Routes>
      </BrowserRouter> 

    );
  }
}

export default App;
