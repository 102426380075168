import React from "react";
import SubstepC from './SubstepC.js';
import SubstepA from './SubstepA.js';

  const Step2 = props => {

    // if (props.currentStep !== 2) {
    //   return null;
    // }
    if( props.state.sistema==="2"){
      return (
      <>
      <SubstepC currentStep={props.state.currentStep} handleChange={props.handleChange} state={props.state}/>
      </>
      );
    } if( props.state.sistema==="1"){
      return (
        <>
        <SubstepA currentStep={props.state.currentStep} handleChange={props.handleChange} state={props.state} />
        </>
        );
    } if(props.state.sistema==="3"){
      return (
        <>
        <SubstepC currentStep={props.state.currentStep} handleChange={props.handleChange} state={props.state} /><SubstepA currentStep={props.state.currentStep} handleChange={props.handleChange} state={props.state} />
        </>
        );
    };
  }
export default Step2;
